<template>
  <div class="container">
    <el-dialog
      :title="$t('courses.view')"
      width="95%"
      top="2.5%"
      :visible.sync="isView"
      center
    >
      <view-course :courseId="courseId"></view-course>
    </el-dialog>
    <div class="ivy-courses-title">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ name: 'Home' }">{{
          $t("courses.home")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          $t("courses.course_management")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t("courses.courses") }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row>
      <el-col :span="24" style="text-align: right">
        <el-button @click="add_new_course" type="primary" icon="el-icon-plus">
          {{ $t("courses.new_course") }}</el-button
        >
      </el-col>
    </el-row>
    <el-tabs value="active">
      <!-- 已上线课程 -->
      <el-tab-pane :label="$t('courses.active')" name="active">
        <div class="ivy-courses-list">
          <el-row>
            <el-col :span="12">
              <h3>{{ $t("courses.courses_list") }}</h3>
            </el-col>
          </el-row>
          <el-table
            :data="active_courses"
            style="width: 100%"
            :empty-text="$t('courses.empty-text')"
          >
            <el-table-column
              prop="course_name"
              :label="$t('courses.course_name')"
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="author"
              :label="$t('courses.teachers')"
              width="120"
            >
            </el-table-column>
            <el-table-column :label="$t('courses.cover')" width="150">
              <template slot-scope="scope">
                <el-image
                  style="height: 50px;width:70px"
                  :src="scope.row.cover_photo"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column :label="$t('courses.price')" width="150">
              <template slot-scope="scope">
                {{
                  addCurrencySymbol(scope.row.price, scope.row.price_currency)
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="created_at"
              :label="$t('courses.date')"
              width="200"
            >
            </el-table-column>
            <el-table-column fixed="right" label="Actions" width="250">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  icon="el-icon-view"
                  @click="addStudents(scope.row.course_id)"
                  >{{ $t("courses.view") }}</el-button
                >
                <el-button
                  @click="publishCourse(scope.row, 0)"
                  type="text"
                  size="mini"
                  icon="el-icon-remove"
                  >{{ $t("courses.inactive") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <!-- 未上线课程 -->
      <el-tab-pane :label="$t('courses.inactive')" name="inactive">
        <div class="ivy-courses-list">
          <el-row>
            <el-col :span="12">
              <h3>{{ $t("courses.pending_courses") }}</h3>
            </el-col>
          </el-row>
          <el-table
            :data="inactive_courses"
            style="width: 100%"
            :empty-text="$t('courses.empty-text')"
          >
            <el-table-column
              prop="course_name"
              :label="$t('courses.course_name')"
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="author"
              :label="$t('courses.creator')"
              width="120"
            >
            </el-table-column>
            <el-table-column :label="$t('courses.cover')" width="150">
              <template slot-scope="scope">
                <el-image
                  style="height: 50px;width:70px"
                  :src="scope.row.cover_photo"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column :label="$t('courses.price')" width="150">
              <template slot-scope="scope">
                {{
                  addCurrencySymbol(scope.row.price, scope.row.price_currency)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="created_at" :label="$t('courses.date')">
            </el-table-column>
            <el-table-column fixed="right" label="Actions" width="250">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  icon="el-icon-view"
                  @click="viewCourse(scope.row.course_id)"
                  >{{ $t("courses.view") }}</el-button
                >

                <el-button type="text" size="mini">
                  <router-link
                    :to="{
                      name: 'EditCourse',
                      query: { id: scope.row.course_id }
                    }"
                    tag="span"
                    class="courses-manage-link"
                  >
                    <i class="el-icon-edit"></i>
                    {{ $t("courses.edit") }}
                  </router-link>
                </el-button>

                <el-button
                  @click="publishCourse(scope.row, 1)"
                  type="text"
                  size="mini"
                  icon="el-icon-upload2"
                  >{{ $t("courses.publish") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import onlineCourseApi from "@/apis/onlineCourse";
import ViewCourse from "@/views/courses/components/ViewCourse";
import moneyMixin from "@/mixins/money";

export default {
  components: {
    ViewCourse
  },
  mixins: [moneyMixin],
  props: [],
  data() {
    return {
      courses: [],
      isView: false,
      courseId: "",
      inactive_courses: [],
      active_courses: []
    };
  },
  computed: {
    ...mapGetters("user", ["langValue"])
  },
  watch: {},
  mounted() {
    this.getCourses();
  },
  methods: {
    async getCourses() {
      const data = await onlineCourseApi.managerDashboard({
        lang: this.langValue
      });
      if (data.status_code != 0) {
        this.inactive_courses = data.offline_courses;
        this.active_courses = data.online_courses;
      }
    },
    viewCourse(course_id) {
      this.courseId = course_id;
      this.isView = true;
    },
    add_new_course() {
      this.$confirm(this.$t("courses.tip_message"), this.$t("courses.tip"), {
        confirmButtonText: this.$t("alert.confirm"),
        cancelButtonText: this.$t("alert.cancel"),
        type: "warning"
      })
        .then(async () => {
          const data = await onlineCourseApi.addNewCourse();
          this.$router.push({ name: "EditCourse", query: { id: data } });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("courses.canceled")
          });
        });
    },
    //课程上线/下线
    async publishCourse(course, effective) {
      let course_id = course.course_id;
      await onlineCourseApi.verifyOnlineCourse({
        course_id,
        effective: effective
      });
      this.getCourses();
    },
    addStudents(course_id) {
      this.$router.push({
        name: "courseDetail",
        query: { id: course_id }
      });
    }
  }
};
</script>
<style scoped>
.ivy-courses-title {
  margin: 20px 0 20px 0;
}
.backend-body .container .el-dialog__wrapper >>> .el-dialog {
  height: 90%;
  margin: 2.5%;
  overflow: auto;
}
.ivy-courses-list {
  background: #fff;
  margin: 20px 0;
  padding: 30px;
  border: 1px solid #dcdfe6;
}
</style>
